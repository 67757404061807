<template>
    <BT-Blade-Items
        addBladeName="external-party-option"
        bladeName="external-party-options"
        hideBackButton
        :hideBladeHeader="inline"
        :hideHeader="inline"
        :loadingMsg="loadingMsg"
        navigation="external-party-options"
        :onFilter="onFilter"
        :onPullSuccessAsync="onPull"
        :refreshToggle="refreshToggle"
        title="3rd Party Integration"
        useServerPagination>
        <template v-slot:body="{ items }">
            <BT-Snack v-model="msg" />
            <BT-Dialog
                color="primary"
                hideButton
                :item="currentOption"
                label="Your Shopify URL"
                @ok="requestParty"
                :showToggle="dialogToggle"
                width="450">
                <template v-slot>
                    <div v-if="currentOption != null">
                        <v-text-field
                            v-if="currentOption.id == 'shopify'"
                            v-model="shopifyURL"
                            prefix="https://"
                            suffix=".myshopify.com" />
                        <v-text-field
                            v-if="currentOption.id == 'myob'"
                            v-model="myobCompanyName"
                            label="MYOB Company Name" />
                        <v-text-field
                            v-if="currentOption.id == 'myob'"
                            v-model="myobPassword"
                            label="Password"
                            type="password" />
                    </div>
                </template>
            </BT-Dialog>
            <v-row :no-gutters="small">
                <v-col
                    v-for="(item, index) in items"
                    :key="index"
                    cols="auto">
                    <v-card
                        @click="$emit('selected', item)"
                        class="ma-1"
                        :width="small ? 250 : 350"
                        :height="small ? 200 : 350"
                        elevation="3">
                        <div 
                            :height="small ? 75 : 150"
                            :width="small ? 100 : 200">
                            <v-img
                                :max-height="small ? 75 : 150"
                                :max-width="small ? 100 : 200"
                                class="mx-auto"
                                :src="imageURL(item.id + '.png')">
                                <template v-slot:placeholder>
                                    <v-icon :size="small ? 80 : 175" color="primary">mdi-account</v-icon>
                                </template>
                            </v-img>
                        </div>
                        
                        <v-card-title>{{ item.optionName }}</v-card-title>

                        <v-card-actions>
                            <v-btn :disabled="item.credential != null && item.credential.isConnected" color="primary" text @click="requestParty(item)">
                                <span v-if="item.credential != null && item.credential.isConnected">Connected</span>
                                <span v-else>Connect</span>
                            </v-btn>

                            <v-spacer />
                            
                            <v-icon class="ml-auto" v-if="item.credential != null && item.credential.isConnected" color="success">mdi-check-outline</v-icon>

                        </v-card-actions>

                        <v-divider v-if="!inline" class="mx-4" />

                        <v-card-actions v-if="!inline">
                            <v-btn 
                                :disabled="item.credential == null || !item.credential.isConnected"
                                color="primary" 
                                icon 
                                title="Refresh Connection"
                                @click="requestParty(item)"><v-icon>mdi-autorenew</v-icon></v-btn>

                            <v-btn 
                                :disabled="item.credential == null || !item.credential.isConnected" 
                                color="primary" 
                                icon
                                title="Integrate"  
                                :to="{ name: 'external-party-integration', params: { id: item.id } }">
                                <v-icon>mdi-connection</v-icon></v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-overlay v-model="needsRefreshing" absolute class="text-center" :opacity="0.8">
                <BT-Btn
                    @click="refresh"
                    icon="mdi-refresh"
                    label="Refresh" />
            </v-overlay>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'External-Parties-Blade',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
        // BTFastStartBanner: () => import('~components/BT-Fast-Start-Banner.vue')
    },
    data: () => {
        return {
            currentOption: null,
            dialogToggle: false,
            loadingMsg: null,
            msg: null,
            myobCompanyName: null,
            myobPassword: null,
            needsRefreshing: false,
            refreshToggle: false,
            shopifyURL: null
        }
    },
    props: {
        inline: {
            type: Boolean,
            default: false
        },
        onFilter: {
            type: Function,
            default: null
        },
        small: {
            type: Boolean,
            default: false
        },
        syncNavigation: {
            type: String,
            default: null
        }
    },
    computed: {
        possibleExternalParties() {
            return this.syncNavigation == null ? [] : this.$BlitzIt.navigation.findExternalParties(this.syncNavigation);
        }
    },
    methods: {
        async onPull(list) {
            var creds = await this.$BlitzIt.store.getAll('external-party-credentials', null, true);

            var rList = list.map(x => {
                return Object.assign({}, { ...x, credential: creds.find(c => c.externalPartyOptionID == x.id)});
            });

            if (this.syncNavigation != null) {
                return rList.filter(x => this.possibleExternalParties.some(ext => ext.party == x.id));
            }
            else {
                return rList;
            }
        },
        refresh() {
            this.refreshToggle = !this.refreshToggle;
            this.needsRefreshing = false;
        },
        async requestParty(option) {
            try {
                if (option == null) {
                    option = this.currentOption;
                }

                if (option == null) {
                    return;
                }

                this.loadingMsg = 'Connecting';
                
                var getData = {
                    partyID: option.id,
                };

                if (option.id == 'shopify') {
                    if (this.shopifyURL == null) {
                        this.currentOption = option;
                        this.dialogToggle = !this.dialogToggle;
                        return;
                    }
                    else {
                        getData = {
                            shopifyName: this.shopifyURL
                        }
                    }
                }
                else if (option.id == 'myob') {
                    if (this.myobCompanyName == null || this.myobPassword == null) {
                        this.currentOption = option;
                        this.dialogToggle = !this.dialogToggle;
                        return;
                    }
                    else {
                        getData = {
                            myobName: this.myobCompanyName,
                            password: this.myobPassword
                        };
                    }
                }

                var res = null;

                if (option.partyName == 'Slack') {
                    res = await this.$BlitzIt.api.get(this.toCompareString(option.partyName), `/authuri/${option.id}`);
                }
                else if (option.id == 'shopify') {
                    res = await this.$BlitzIt.api.get(option.id, '/authuri', getData);
                }
                else if (option.id == 'myob') {
                    res = await this.$BlitzIt.api.get(option.id, '/authuri', getData);
                }
                else {
                    res = await this.$BlitzIt.api.get(option.id, '/authuri');
                }
                
                if (res.data.data != null) {
                    // window.location = res.data.data;
                    window.open(res.data.data);
                    this.needsRefreshing = true;
                }
                else {
                    this.msg = 'Refresh Failed';
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
    }
}
</script>